import {ads} from './ads'
// import {radioPlayer} from './radio-player'
import {navigation} from './navigation'

(() => {
  ads.rowAdsImages()

  // const player = radioPlayer.initRadioPlayer()
  // $('.play')
  //   .on('click', () => {
  //     radioPlayer.radioPlayPause(player)
  //   })
  // $('.settings').on('click', () => {
  //   radioPlayer.cardSettingsBack()
  // })

  // $('.settings-choose').append(radioPlayer.addRadioQuality(player))
  // $('.settings-choose').on('click', (e) => {
  //   radioPlayer.settingsFrontSet(player, e.target.outerText)
  // })
  $('.routing a').on('click', (e) => {
    if(e.target.hash.replace('#', '') === 'cem') {
      navigation.cem()
    } else {
      navigation.home()
    }
  })
})()
