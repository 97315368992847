export const navigation = {
  home: () => {
    $('aside.left img').show()
    $('aside.left #cem-page').hide()
  },
  cem: () => {
    $('aside.left img').hide()
    $('aside.left #cem-page').show()
  }
}
