import imageAd1 from './../img/ar.jpg'
import imageAd2 from  './../img/arkartinka.jpg'

const adsImagesPath = [
  // imageAd1,
  imageAd2
]
export const ads = {
  rowAdsImages: () => {
    let adIndex = 0
    let imgView = adsImagesPath[adIndex]
    setInterval(() => {
      imgView = adsImagesPath[adIndex]
      if(adIndex >= adsImagesPath.length - 1) {
        adIndex = 0
      } else {
        adIndex++
      }
      document.getElementById('ads').src = imgView
    }, 15000)
    document.getElementById('ads').src = imgView
  }
}
